<template>
	<div class="container">
		<div class="page-sub-box ewm">
			<div class="locker_setting_list sub_new_style01 sub_ui_box1">
				<div class="page_search_box line_bottom_1px">
					<DxValidationGroup ref="searchValidationGroup">
						<div class="flex space-x-2 inner">
							<div class="mt-2">
								기준년월
							</div>
							<DxDateBox
								:edit-enabled="false"
								:styling-mode="stylingMode"
								width="100"
								height="30"
								v-model="searchType.customTypes.dayStart"
								type="date"
								display-format="yyyy.MM"
								dateSerializationFormat="yyyyMMdd"
								dateOutOfRangeMessage="종료일은 시작일보다 크거나 같아야 합니다."
								:max="searchType.customTypes.dayEnd"
								maxLength="7"
								invalid-date-message="입력 데이터가 유효하지 않습니다."
								:calendar-options="{
									zoomLevel: 'year',
									minZoomLevel: 'decade',
									maxZoomLevel: 'year',
								}"
								@value-changed="onDayStartChanged"
							>
								<DxValidator>
									<DxRequiredRule message="기준년월은 필수입니다." />
								</DxValidator>
							</DxDateBox>
							<div class="mt-1">
								~
							</div>
							<DxDateBox
								:styling-mode="stylingMode"
								width="100"
								height="30"
								v-model="searchType.customTypes.dayEnd"
								type="date"
								display-format="yyyy.MM"
								dateSerializationFormat="yyyyMMdd"
								dateOutOfRangeMessage="종료일은 시작일보다 크거나 같아야 합니다."
								:min="searchType.customTypes.dayStart"
								maxLength="7"
								invalid-date-message="입력 데이터가 유효하지 않습니다."
								:calendar-options="{
									zoomLevel: 'year',
									minZoomLevel: 'decade',
									maxZoomLevel: 'year',
								}"
								@value-changed="onDayEndChanged"
							>
								<DxValidator>
									<DxRequiredRule message="기준년월은 필수입니다." />
								</DxValidator>
							</DxDateBox>
							<DxButton text="검색" class="btn_M box-btn-search" type="button" :height="30" @click="selectEducationScheData" />
						</div>
					</DxValidationGroup>
				</div>
			</div>

			<!-- Main Layer -->
			<div class="flex gap-x-6 items-stretch">
				<div class="w-1/3">
					<div class="mt-4">
						<DxDataGrid
							class="grid-box"
							id="dataGrid1"
							ref="dataGrid1"
							:allow-column-reordering="false"
							:data-source="edu.deps1List"
							key-expr="id"
							:show-column-headers="true"
							:show-borders="false"
							:column-auto-width="true"
							:show-row-lines="true"
							:show-column-lines="false"
							:no-data-text="this.$_msgContents('CMN_NO_DATA')"
							@row-click="onClickeduRow('dataGrid1', $event)"
							height="calc(100vh - 380px)"
							:word-wrap-enabled="true"
						>
							<DxFilterRow :visible="true" />
							<DxHeaderFilter :visible="true" />
							<DxPaging :enabled="false" />
							<DxSelection mode="single" />
							<DxLoadPanel :enabled="true" />
							<DxColumn
								caption="교육계획명"
								data-field="scheNm"
								alignment="left"
								height="40"
								:allow-sorting="true"
								:allow-header-filtering="false"
								:allow-filtering="true"
							/>
							<DxColumn
								caption="진행상태"
								data-field="processCd"
								alignment="center"
								:width="110"
								:allow-header-filtering="true"
								:allow-filtering="true"
								:allow-sorting="true"
								:calculate-sort-value="sortByProcessCd"
							>
								<DxLookup :data-source="this.$_enums.ewm.eduEducationProcess.values" value-expr="value" display-expr="label" />
							</DxColumn>
						</DxDataGrid>
					</div>
				</div>

				<div class="w-2/3 flex flex-col gap-y-6 divide-y">
					<div>
						<h3 class="text-xl font-medium my-4">강의 정보</h3>
						<DxDataGrid
							class="grid-box"
							id="dataGrid2"
							ref="dataGrid2"
							:allow-column-reordering="true"
							:data-source="edu.deps2List"
							:show-column-headers="true"
							:show-borders="false"
							:column-auto-width="true"
							:show-row-lines="true"
							:show-column-lines="false"
							:no-data-text="this.$_msgContents('CMN_NO_DATA')"
							@row-click="onClickeduRow('dataGrid2', $event)"
							@cell-hover-changed="onCellHoverChanged"
							height="calc(100vh - 660px)"
						>
							<DxFilterRow :visible="true" />
							<DxSelection mode="single" />
							<DxLoadPanel :enabled="true" />
							<DxHeaderFilter :visible="true" />

							<DxColumn
								caption="강의명"
								data-field="eduNm"
								alignment="center"
								:allow-sorting="false"
								:allow-header-filtering="false"
								:allow-filtering="true"
							/>
							<DxColumn
								caption="강의일정"
								data-field="subjectTimeDisplayValue"
								alignment="center"
								:allow-filtering="true"
								:allow-sorting="false"
							>
							</DxColumn>
							<DxColumn
								caption="강의유형"
								data-field="typeCd"
								alignment="center"
								:allow-filtering="true"
								:allow-sorting="false"
							>
								<DxLookup :data-source="codes.typeCd.dataSource" value-expr="codeId" display-expr="codeNm" />
							</DxColumn>
							<DxColumn
								caption="진행방식"
								data-field="divisionCd"
								alignment="center"
								:allow-filtering="true"
								:allow-sorting="false"
							>
								<DxLookup :data-source="codes.divisionCd.dataSource" value-expr="codeId" display-expr="codeNm" />
							</DxColumn>
							<DxColumn
								caption="강사"
								data-field="teachers"
								alignment="center"
								cell-template="teacherTemplate"
								:allow-filtering="false"
								:allow-sorting="false"
							>
							</DxColumn>
							<DxColumn
								caption="참석"
								data-field="joinCount"
								alignment="center"
								:width="80"
								:allow-filtering="false"
								:allow-sorting="false"
							/>
							<DxColumn
								caption="불참"
								data-field="nonJoinCount"
								alignment="center"
								:width="80"
								:allow-filtering="false"
								:allow-sorting="false"
							/>
							<DxColumn
								caption="피드백"
								data-field="feedBackCount"
								alignment="center"
								:allow-filtering="false"
								:allow-sorting="false"
							/>
							<template #teacherTemplate="{ data }">
								<div v-if="data.row.data.teachers.length == 1">
									{{ data.row.data.teachers[0].teacherName }}
									[{{ data.row.data.teachers[0].teacherId }}]
								</div>
								<div v-else-if="data.row.data.teachers.length > 1">{{ data.row.data.teachers.length }}명</div>
							</template>
						</DxDataGrid>
					</div>

					<div>
						<h3 class="text-xl font-medium my-4">대상자 정보</h3>
						<DxDataGrid
							class="grid-box"
							id="dataGrid3"
							ref="dataGrid3"
							:allow-column-reordering="true"
							:data-source="edu.deps3List"
							:show-column-headers="true"
							:show-borders="false"
							:column-auto-width="true"
							:show-row-lines="true"
							:show-column-lines="false"
							:no-data-text="this.$_msgContents('CMN_NO_DATA')"
							height="calc(100vh - 660px)"
						>
							<DxFilterRow :visible="true" />
							<DxLoadPanel :enabled="false" />
							<DxHeaderFilter :visible="true" />
							<DxSelection mode="single" />

							<DxColumn
								caption="부서정보"
								data-field="deptNmPath"
								alignment="center"
								:allow-sorting="false"
								:allow-header-filtering="false"
							>
							</DxColumn>
							<DxColumn
								caption="교육대상자"
								data-field="agtNm"
								cell-template="targetTemplate"
								alignment="center"
								:allow-filtering="true"
								:allow-sorting="false"
								:allow-header-filtering="false"
							/>
							<DxColumn
								caption="강의참석여부"
								data-field="joinCd"
								alignment="center"
								:allow-sorting="false"
								:allow-filtering="true"
								:allow-header-filtering="true"
							>
								<DxLookup :data-source="codes.joinCd.dataSource" value-expr="codeId" display-expr="codeNm" />
							</DxColumn>
							<DxColumn
								caption="피드백보기"
								data-field="feedback"
								cell-template="feedbackTemplate"
								alignment="center"
								:allow-filtering="false"
								:allow-sorting="false"
							/>
							<template #targetTemplate="{ data }">
								{{ data.row.data.agtNm }}
								[{{ data.row.data.agtId }}]
							</template>
							<template #feedbackTemplate="{ data }">
								<div v-if="data.row.data.joinCd === 979">
									<DxButton
										class="btn_XS white light_filled mr-0"
										:width="50"
										:height="30"
										text="확인"
										type="button"
										styling-mode="contained"
										@click="onClickFeedback(data.row)"
									/>
								</div>
								<div v-else-if="data.row.data.joinCd === 980">
									{{ '-' }}
								</div>
							</template>
						</DxDataGrid>
					</div>
				</div>
			</div>
			<!-- /Main Layer -->
		</div>

		<DxPopover width="auto" :visible="defaultVisible" :target="hoverTarget" position="top">
			<div v-html="popover_description"></div>
		</DxPopover>

		<!-- Modal Layer -->
		<DxPopup
			:show-title="true"
			:title="modal.initData ? modal.initData.title : null"
			:min-width="modal.initData ? modal.initData.width : null"
			:width="modal.initData ? modal.initData.width : null"
			:min-height="modal.initData ? modal.initData.height : null"
			:height="modal.initData ? modal.initData.height : null"
			:drag-enabled="true"
			:resize-enabled="true"
			:show-close-button="true"
			:close-on-outside-click="false"
			v-model="modal.isOpened"
			:visible="modal.isOpened"
			@hiding="isOpenModal(false)"
		>
			<template #content>
				<div>
					<component
						ref="modalFeedback"
						:is="modal.currentComponent"
						:contentData="modal.contentData"
						v-model="modal.contentData"
					></component>
				</div>
			</template>
			<DxToolbarItem
				widget="dxButton"
				toolbar="bottom"
				location="center"
				:visible="
					modal.initData.hasOwnProperty('buttons')
						? modal.initData.buttons.hasOwnProperty('save')
							? modal.initData.buttons.hasOwnProperty('save')
							: false
						: false
				"
				:options="{
					elementAttr: {
						class: 'default filled txt_S medium',
					},
					text: modal.initData.hasOwnProperty('buttons')
						? modal.initData.buttons.hasOwnProperty('save')
							? modal.initData.buttons.save.text
							: ''
						: '',
					width: '120',
					height: '40',
					onClick: () => {
						onConfirmModal();
					},
				}"
			/>
			<DxToolbarItem
				widget="dxButton"
				toolbar="bottom"
				location="center"
				:visible="
					modal.initData.hasOwnProperty('buttons')
						? modal.initData.buttons.hasOwnProperty('cancel')
							? modal.initData.buttons.hasOwnProperty('cancel')
							: !modal.initData.buttons.hasOwnProperty('cancel')
						: false
				"
				:options="{
					elementAttr: {
						class: 'white filled txt_S medium',
					},
					text: modal.initData.hasOwnProperty('buttons')
						? modal.initData.buttons.hasOwnProperty('cancel')
							? modal.initData.buttons.cancel.text
							: ''
						: '',
					width: '120',
					height: '40',
					onClick: () => {
						isOpenModal(false);
					},
				}"
			/>
		</DxPopup>
		<!-- /Modal Layer -->
	</div>
</template>

<script>
import DxDateBox from 'devextreme-vue/date-box';
import { DxButton } from 'devextreme-vue/button';
import { DxPopup, DxToolbarItem } from 'devextreme-vue/popup';
import { DxPopover } from 'devextreme-vue/popover';
import { DxDataGrid, DxColumn, DxPaging, DxSelection, DxFilterRow, DxHeaderFilter, DxLookup, DxLoadPanel } from 'devextreme-vue/data-grid';
import { getPastFromToday, isSuccess } from '@/plugins/common-lib';
import { DxValidator, DxRequiredRule } from 'devextreme-vue/validator';
import { DxValidationGroup } from 'devextreme-vue/validation-group';

import ModalFeedback from '@/pages/ewm/education/implementation/status/modal-feedback.vue';

let vm = this;

export default {
	components: {
		DxDateBox,
		DxButton,
		DxPopup,
		DxToolbarItem,
		DxPopover,
		DxDataGrid,
		DxColumn,
		DxPaging,
		DxSelection,
		DxFilterRow,
		DxHeaderFilter,
		DxLookup,
		DxLoadPanel,
		DxRequiredRule,
		DxValidator,
		DxValidationGroup,

		ModalFeedback,
	},
	props: {},
	watch: {},
	data() {
		return {
			defaultVisible: false,
			popover_caption: '',
			popover_description: '',
			hoverTarget: '',
			stylingMode: 'outlined',
			searchType: {
				customTypes: {
					dayStart: getPastFromToday(11, 'months'),
					dayEnd: getPastFromToday(0, 'days'),
				},
				paramsData: null,
			},
			modal: {
				isOpened: false,
				currentComponent: null,
				initData: {},
				contentData: null,
				feedbackData: {
					//선택된 데이터
					deps1List: [],
					deps2List: [],
					deps3List: [],
				},
			},
			edu: {
				deps1List: [],
				deps2List: [],
				deps3List: [],
			},
			codes: {
				joinCd: {
					dataSource: [],
					displayExpr: 'codeNm',
					valueExpr: 'codeId',
				},
				divisionCd: {
					dataSource: [],
					displayExpr: 'codeNm',
					valueExpr: 'codeId',
				},
				typeCd: {
					dataSource: [],
					displayExpr: 'codeNm',
					valueExpr: 'codeId',
				},
			},
		};
	},
	computed: {
		searchValidationGroup: function() {
			return this.$refs['searchValidationGroup'].instance;
		},
		ewmCodes() {
			return this.$_enums.ewm;
		},
	},
	methods: {
		/** @description : 강사컬럼 마우스 hover 이벤트 */
		onCellHoverChanged(e) {
			this.defaultVisible = false;
			this.hoverTarget = e.cellElement;
			if (e.rowType === 'data' && e.eventType === 'mouseover') {
				if (e.column.dataField == 'teachers') {
					if (e.value.length > 0) {
						let teacherText = '';
						e.value.forEach(val => (teacherText += `<li>${val.teacherName}[${val.teacherId}]</li>`));
						this.popover_caption = '강사';
						this.popover_description = `<ul class="teacher-list">${teacherText}</ul>`;
						this.defaultVisible = true;
					}
				}
			}
		},
		/** @description : 소트설정 */
		sortByProcessCd(rowData) {
			return this.$_enums.ewm.eduEducationProcess.values.find(e => rowData.processCd == e.value).label;
		},
		sortByTypeCd(rowData) {
			return this.codes.typeCd.dataSource.find(e => rowData.typeCd == e.codeId).codeNm;
		},
		sortByDivisionCd(rowData) {
			return this.codes.divisionCd.dataSource.find(e => rowData.divisionCd == e.value).label;
		},
		sortByJoinCd(rowData) {
			return this.$_enums.ewm.join.values.find(e => rowData.joinCd == e.value).label;
		},
		/** @description : 강의일정 표시 */
		subjectTimeDisplayValue(res) {
			res.forEach(item => {
				let formattedDate =
					this.formatDate(item.eduDt) +
					` ` +
					this.formatTime(item.subjectTimeStart) +
					` ~ ` +
					this.formatDate(item.eduDt) +
					` ` +
					this.formatTime(item.subjectTimeEnd);
				item.subjectTimeDisplayValue = formattedDate;
			});
		},
		/** @description : 날짜 형식 변환 메서드 */
		formatDate(date) {
			return this.$_commonlib.formatDate(date, 'YYYYMMDD', 'YYYY-MM-DD');
		},
		/** @description : 시간 형식 변환 메서드 */
		formatTime(time) {
			return this.$_commonlib.formatDate(time, 'HHmmss', 'HH:mm');
		},
		async selectEducationScheData() {
			if (!vm.searchValidationGroup.validate().isValid) {
				return;
			}

			let params = {
				frScheYmd: vm.searchType.customTypes.dayStart,
				toScheYmd: vm.searchType.customTypes.dayEnd,
			};

			let payload = {
				actionname: 'EWM_EDUCATION_STATUS_SCHEDULE_LIST',
				data: params,
				loading: false,
			};

			let res = await this.CALL_EWM_API(payload);

			if (isSuccess(res)) {
				this.edu.deps1List = this.$_commonlib.cloneObj(res.data.data);
				this.edu.deps2List = [];
				this.edu.deps3List = [];
			}
		},
		/** @description : 트리 로우 클릭 이벤트 */
		onClickeduRow(gridId, row) {
			let rowData;

			if (gridId === 'dataGrid1') {
				rowData = row.data;
				this.modal.feedbackData.deps1List = rowData;
			} else if (gridId === 'dataGrid2') {
				rowData = this.$refs[gridId].instance.getSelectedRowsData()[0];
				this.modal.feedbackData.deps2List = rowData;
			}
			let params = {
				gridId,
				pid: rowData.id,
				scheId: rowData.id,
				scheRegId: rowData.id,
			};
			this.selectDataList(params);
		},
		async selectDataList(params) {
			if (params.gridId === 'dataGrid1') {
				let payload = {
					actionname: 'EWM_EDUCATION_STATUS_SUBJECT_LIST',
					data: params,
					loading: false,
				};

				let res = await this.CALL_EWM_API(payload);

				this.edu.deps2List = this.$_commonlib.cloneObj(res.data.data);
				this.edu.deps3List = [];
				this.subjectTimeDisplayValue(this.edu.deps2List);
			} else if (params.gridId === 'dataGrid2') {
				let payload = {
					actionname: 'EWM_EDUCATION_STATUS_TARGET_LIST',
					data: params,
					loading: false,
				};

				let res = await this.CALL_EWM_API(payload);

				this.edu.deps3List = this.$_commonlib.cloneObj(res.data.data);
			}
		},
		/** @description : 날짜 검색 */
		onChangeSearchDay() {
			const regDt = this.searchType.customTypes.dayStart + '000000' + '~' + this.searchType.customTypes.dayEnd + '235959';
			this.searchType.paramsData = { ...this.searchType.paramsData, regDt };

			this.$_setSearchHistsCustomType(this.searchType.paramsData);
		},
		/** @description : 피드백 버튼 */
		onClickFeedback(row) {
			this.modal.feedbackData.deps3List = row.data;
			if (this.modal.feedbackData.deps1List.processCd === this.$_enums.ewm.eduEducationProcess.FEEDBACK.value) {
				this.onOpenModal(
					'ModalFeedback',
					{
						title: '피드백 보기',
						buttons: {
							save: { text: '저장' },
							cancel: { text: '닫기' },
						},
						width: 600,
						height: 600,
					},
					this.modal.feedbackData,
				);
			} else {
				this.onOpenModal(
					'ModalFeedback',
					{
						title: '피드백 보기',
						buttons: {
							cancel: { text: '닫기' },
						},
						width: 600,
						height: 600,
					},
					this.modal.feedbackData,
				);
			}
		},
		onOpenModal(componentNm, componentInitData, data) {
			this.modal.currentComponent = componentNm;
			this.modal.initData = componentInitData;
			this.modal.contentData = data;

			this.isOpenModal(true);
		},
		/** @description: 팝업 오픈 체크 메서드 */
		isOpenModal(data) {
			this.modal.isOpened = data;
			if (!data) {
				this.modal.currentComponent = null;
				this.modal.initData = {};
			}
		},
		/** @desc: 모달창이 close될 때, modal 객체 설정 값 초기화 */
		setInitModal() {
			this.modal.isOpened = false;
			this.modal.currentComponent = null;
			this.modal.initData = {};
		},
		/** @description: 피드백 저장 버튼 이벤트 */
		async onConfirmModal() {
			if (
				!(await this.$_Confirm(this.$_msgContents('COMMON.MESSAGE.CMN_CFM_SAVE', { defaultValue: '저장하시겠습니까?' }), {
					title: `<h2>알림</h2>`,
				}))
			) {
				return;
			}
			let params = {
				//feedbakResponse: this.modal.contentData.deps3List.feedbakResponse,
				feedbakResponse: this.$refs.modalFeedback.formData.feedbakResponse,
				id: this.modal.contentData.deps3List.id,
			};
			const payload = {
				actionname: 'EWM_EDUCATION_STATUS_FEEDBACK_RESPONSE_SAVE',
				data: params,
				loading: false,
			};
			const res = await this.CALL_EWM_API(payload);

			this.setInitModal();

			if (isSuccess(res)) {
				this.$_Toast(this.$_msgContents('CMN_SUC_SAVE', { defaultValue: '정상적으로 저장되었습니다.' }));
				this.onClickeduRow('dataGrid2', null);
			}
		},
		/** @description : 데이터 없을시 출력 */
		noEDUDataText(length) {
			if (length === 0) {
				return '조회된 교육계획이 없습니다.';
			}
		},
		noSubjectDataText(length) {
			if (length === 0) {
				return '강의정보가 없습니다.';
			}
		},
		noTargetDataText(length) {
			if (length === 0) {
				return '대상자 정보가 없습니다.';
			}
		},
		onDayStartChanged(e){
			const changed = e.value.slice(0, 6)+'01';
			this.searchType.customTypes.dayStart = changed;
		},
		onDayEndChanged(e){
			const changed = e.value.slice(0, 6)+'29';
			this.searchType.customTypes.dayEnd = changed;
		},
		/** @description : 셀렉트 박스 초기화 */
		async initCodeMap() {
			this.codeMap = await this.$_getCodeMapList(
				'root_join, root_ewm_edu_education_process_division, root_ewm_edu_education_type, root_ewm_edu_education_process',
			);
		},
		/** @description : 라이프 사이클 created시 호출되는 메서드 */
		createdData() {
			vm = this;

			this.initCodeMap().then(() => {
				this.codes.joinCd.dataSource = this.$_fetchCodesByDepth(this.codeMap['root_join'], 2);
				this.codes.divisionCd.dataSource = this.$_fetchCodesByDepth(this.codeMap['root_ewm_edu_education_process_division'], 2);
				this.codes.typeCd.dataSource = this.$_fetchCodesByDepth(this.codeMap['root_ewm_edu_education_type'], 2);
			});
		},
		mountData() {
			this.selectEducationScheData();
		},
	},
	created() {
		this.createdData();
	},
	mounted() {
		this.mountData();
	},
};
</script>
